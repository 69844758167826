import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'

import SEO from "../components/seo";

import Container from '../components/UI/Container';
import TeamMemberCard from '../components/TeamMemberCard';

const StyledWhiteBackground = styled.div`
  background: ${props => props.theme.colors.white};
  min-width: 1000px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: -2;

  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    min-width: 600px;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    display: none;
  }
`

const TeamPage = () => {

  const data = useStaticQuery(graphql`
    query TeamES {
      teamJson {
        mayckon {
          es {
            description1
            description2
            description3
            name
            portfolio
            role1
            role2
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Equipo" />
      <Container>
      <TeamMemberCard
          id="mayckon"
          reverse={false}
          photo="/images/team/mayckon.webp"
          name={data.teamJson.mayckon.es.name}
          role1={data.teamJson.mayckon.es.role1}
          role2={data.teamJson.mayckon.es.role2}
          description1={data.teamJson.mayckon.es.description1}
          description2={data.teamJson.mayckon.es.description2}
          description3={data.teamJson.mayckon.es.description3}
          portfolio={data.teamJson.mayckon.es.portfolio}
      />
        <StyledWhiteBackground />
      </Container>
    </>
  );
}

export default TeamPage;

